function createJobNoteDto(jobId, note, createdBy) {
  return {
    jobId: jobId,
    note: note,
    createdBy: createdBy
  }
}

function fetchScheduleSlotDto(franchiseId, from, to) {
  return {
    franchiseId: franchiseId,
    from: from,
    to: to
  }
}

function putJunkJobDto(jobId, date, operatingUnitId, franchiseId, capacityId, modifiedBy) {
  return {
    jobId: jobId,
    date: date,
    operatingUnitId: operatingUnitId,
    franchiseId: franchiseId,
    capacityId: capacityId,
    modifiedBy: modifiedBy
  }
}

function putJunkJobSatelliteOfficeDto(jobId, franchiseSatelliteOfficeId, modifiedBy) {
  return {
    jobId: jobId,
    franchiseSatelliteOfficeId: franchiseSatelliteOfficeId,
    modifiedBy: modifiedBy
  }
}

function putJobPriceDto(jobId, franchiseCapacityId, price, modifiedBy, operatingUnitId, scheduledDate) {
  return {
    jobId: jobId,
    franchiseCapacityId: franchiseCapacityId,
    price: price,
    modifiedBy: modifiedBy,
    operatingUnitId: operatingUnitId,
    scheduledDate: scheduledDate
  }
}

function putJobPreferredTimeSlotDto(jobId, preferredTimeSlotId, modifiedBy) {
  return {
    jobId: jobId,
    timeSlotId: preferredTimeSlotId,
    modifiedBy: modifiedBy
  }
}

function putJobSelectedFranchiseTaxDto(jobId, franchiseTaxId) {
  return {
    jobId: jobId,
    franchiseTaxId: franchiseTaxId
  }
}

function createJobEmployeeDto(jobId, employeeId, createdBy) {
  return {
    jobId: jobId,
    employeeId: employeeId,
    createdBy: createdBy
  }
}

function putJobEmployeeIsDriverDto(jobEmployeeId, isDriver, modifiedBy) {
  return {
    jobEmployeeId: jobEmployeeId,
    isDriver: isDriver,
    modifiedBy: modifiedBy
  }
}

function putJunkJobTruckStartDateTimeDto(jobTruckId, startDateTime, modifiedBy) {
  return {
    jobTruckId: jobTruckId,
    startDateTime: startDateTime,
    modifiedBy: modifiedBy
  }
}

function putJunkJobTruckEndDateTimeDto(jobTruckId, endDateTime, modifiedBy) {
  return {
    jobTruckId: jobTruckId,
    endDateTime: endDateTime,
    modifiedBy: modifiedBy
  }
}

function putJunkJobEmployeeTipDto(tipAmount, modifiedBy) {
  return {
    tipAmount: tipAmount,
    modifiedBy: modifiedBy
  }
}

function createJobTruckDto(jobId, truckId, startDateTime, endDateTime, modifiedBy) {
  return {
    jobId: jobId,
    truckId: truckId,
    startDateTime: startDateTime,
    endDateTime: endDateTime,
    modifiedBy: modifiedBy
  }
}

function updateJunkJobTruckDownTimeDto(jobTruckId, downTime, modifiedBy) {
  return {
    jobTruckId: jobTruckId,
    downTime: downTime,
    modifiedBy: modifiedBy
  }
}

function updateTrucksAndEmployeeGlobalTimesDto(jobId, jobTruckIds, downTime, startDateTime, endDateTime, modifiedBy) {
  return {
    jobId: jobId,
    jobTruckIds: jobTruckIds,
    downTime: downTime,
    startDateTime: startDateTime,
    endDateTime: endDateTime,
    modifiedBy: modifiedBy
  }
}

function uncompleteJobDto(jobId, modifiedBy) {
  return {
    jobId: jobId,
    modifiedBy: modifiedBy
  }
}

function scheduleJobDto(createdBy) {
  return {
    createdBy: createdBy
  }
}

function abandonJobDto(modifiedBy, abandonmentReasonId) {
  return {
    modifiedBy: modifiedBy,
    abandonmentReasonId: abandonmentReasonId
  }
}

function cancelJobDto(modifiedBy, cancellationReasonId) {
  return {
    modifiedBy: modifiedBy,
    cancellationReasonId: cancellationReasonId
  }
}

function claimJobDto(modifiedBy, jobId, jobEmployeeIds, truckIds, drivers, defaultStartAndEndDateTime) {
  return {
    modifiedBy: modifiedBy,
    jobId: jobId,
    employeeIds: jobEmployeeIds,
    truckIds: truckIds,
    drivers: drivers,
    defaultStartAndEndDateTime: defaultStartAndEndDateTime
  }
}

function disclaimJobDto(modifiedBy) {
  return {
    modifiedBy: modifiedBy
  }
}

function postJobEmployeeIndirectLaborDto(employeeId, jobId, tipAmount, createdBy) {
  return {
    employeeId: employeeId,
    jobId: jobId,
    tipAmount: tipAmount,
    createdBy: createdBy
  }
}

function closeJobDto(modifiedBy) {
  return {
    modifiedBy: modifiedBy
  }
}

function completeJobDto(modifiedBy) {
  return {
    modifiedBy: modifiedBy
  }
}

function createCustomerAddressDto(addressLine1, addressLine2, city, stateCode, postalCode, countryCode, latitude, longitude, isUserValidated, complex) {
  return {
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    city: city,
    stateCode: stateCode,
    postalCode: postalCode,
    countryCode: countryCode,
    latitude: latitude,
    longitude: longitude,
    isUserValidated: isUserValidated,
    complex: complex
  }
}

function putCustomerAddressDto(customerId, addressId, addressLine2, complex) {
  return {
    customerId: customerId,
    addressId: addressId,
    addressLine2: addressLine2,
    complex: complex
  }
}

function putCustomerAddressUserValidatedDto(customerId, addressId, addressLine1, addressLine2, city, stateCode, postalCode, countryCode, complex) {
  return {
    customerId: customerId,
    addressId: addressId,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    city: city,
    stateCode: stateCode,
    postalCode: postalCode,
    countryCode: countryCode,
    complex: complex
  }
}

function createCustomerContactDto(firstName, lastName, phonePrimary, phonePrimaryExtension, phoneSecondary, phoneSecondaryExtension, customerId, isOptedInForTextMessaging, isPrimary, email, createdBy, preferredMethodOfContactId, refusedToProvideEmail) {
  return {
    firstName: firstName,
    lastName: lastName,
    phonePrimary: phonePrimary,
    phonePrimaryExtension: phonePrimaryExtension,
    phoneSecondary: phoneSecondary,
    phoneSecondaryExtension: phoneSecondaryExtension,
    customerId: customerId,
    isOptedInForTextMessaging: isOptedInForTextMessaging,
    isPrimary: isPrimary,
    email: email,
    createdBy: createdBy,
    preferredMethodOfContactId: preferredMethodOfContactId,
    refusedToProvideEmail: refusedToProvideEmail
  }
}

function createCustomerDto(businessName, customerTypeId, preferredMethodOfContactId, franchiseId, firstName, lastName, phonePrimary, phonePrimaryExtension, phoneSecondary, phoneSecondaryExtension, email, createdBy, isTaxExempt, scheduledDate, jobCreationOriginId, refusedToProvideEmail, employerIdentificationNumber) {
  return {
    businessName: businessName,
    customerTypeId: customerTypeId,
    preferredMethodOfContactId: preferredMethodOfContactId,
    franchiseId: franchiseId,
    firstName: firstName,
    lastName: lastName,
    phonePrimary: phonePrimary,
    phonePrimaryExtension: phonePrimaryExtension,
    phoneSecondary: phoneSecondary,
    phoneSecondaryExtension: phoneSecondaryExtension,
    email: email,
    createdBy: createdBy,
    isTaxExempt: isTaxExempt,
    scheduledDate: scheduledDate,
    jobCreationOriginId: jobCreationOriginId,
    refusedToProvideEmail: refusedToProvideEmail,
    employerIdentificationNumber: employerIdentificationNumber
  }
}

function customerSearchDto(firstName, lastName, phoneNumber, email, businessName, jobNumber, page, pageSize, operatingUnitAccessList, resultSetLimit = 1000) {
  return {
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    email: email,
    businessName: businessName,
    jobNumber: jobNumber,
    page: page,
    pageSize: pageSize,
    operatingUnitAccessList: operatingUnitAccessList,
    resultSetLimit: resultSetLimit
  }
}

function putOperatingUnitDto(operatingUnitId, operatingUnitName, operatingUnitDotNumber) {
  return {
    operatingUnitId: operatingUnitId,
    operatingUnitName: operatingUnitName,
    operatingUnitDotNumber: operatingUnitDotNumber
  }
}

function putFranchiseDetailsDto(franchiseId, franchiseName, phone, dialpadPhone, email, isMiniMarket, isRoyaltyExempt, systemAccessDate, businessStartDate, businessEndDate, modifiedBy, googleReviewUrl) {
  return {
    franchiseId: franchiseId,
    franchiseName: franchiseName,
    phone: phone,
    dialpadPhone: dialpadPhone,
    email: email,
    isMiniMarket: isMiniMarket,
    isRoyaltyExempt: isRoyaltyExempt,
    systemAccessDate: systemAccessDate,
    businessStartDate: businessStartDate,
    businessEndDate: businessEndDate,
    modifiedBy: modifiedBy,
    googleReviewUrl: googleReviewUrl
  }
}

function putFranchiseDetailsWithUpdatedAddressDto(franchiseId, franchiseName, phone, dialpadPhone, email, isMiniMarket, isRoyaltyExempt, systemAccessDate, businessStartDate, businessEndDate, modifiedBy, googleReviewUrl, address) {
  return {
    franchiseId: franchiseId,
    franchiseName: franchiseName,
    phone: phone,
    dialpadPhone: dialpadPhone,
    email: email,
    isMiniMarket: isMiniMarket,
    isRoyaltyExempt: isRoyaltyExempt,
    systemAccessDate: systemAccessDate,
    businessStartDate: businessStartDate,
    businessEndDate: businessEndDate,
    modifiedBy: modifiedBy,
    googleReviewUrl: googleReviewUrl,
    validatedAddressDto: {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      stateCode: address.stateCode,
      postalCode: address.postalCode,
      countryCode: address.countryCode,
      isUserValidated: address.isUserValidated,
      latitude: address.latitude,
      longitude: address.longitude
    }
  }
}

function putSatelliteOfficeDetailsDto(satelliteOfficeId, satelliteOfficeName, phone, beginDateTime, endDateTime, modifiedBy, googleReviewUrl) {
  return {
    satelliteOfficeId: satelliteOfficeId,
    satelliteOfficeName: satelliteOfficeName,
    phone: phone,
    beginDateTime: beginDateTime,
    endDateTime: endDateTime,
    modifiedBy: modifiedBy,
    googleReviewUrl: googleReviewUrl
  }
}

function putSatelliteOfficeDetailsWithUpdatedAddressDto(satelliteOfficeId, satelliteOfficeName, phone, beginDateTime, endDateTime, modifiedBy, googleReviewUrl, address) {
  return {
    satelliteOfficeId: satelliteOfficeId,
    satelliteOfficeName: satelliteOfficeName,
    phone: phone,
    beginDateTime: beginDateTime,
    endDateTime: endDateTime,
    modifiedBy: modifiedBy,
    googleReviewUrl: googleReviewUrl,
    validatedAddressDto: {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      stateCode: address.stateCode,
      postalCode: address.postalCode,
      countryCode: address.countryCode,
      isUserValidated: address.isUserValidated,
      latitude: address.latitude,
      longitude: address.longitude
    }
  }
}

function createOperatingUnitDto(operatingUnitName, operatingUnitNumber, dotNumber, isTestOperatingUnit) {
  return {
    name: operatingUnitName,
    number: operatingUnitNumber,
    dotNumber: dotNumber,
    isTestOperatingUnit: isTestOperatingUnit
  }
}

function createSatelliteOfficeDto(franchiseId, phonePrimary, satelliteOfficeName, beginDateTime, modifiedBy, googleReviewUrl, addressLine1, addressLine2, city, stateCode, postalCode, countryCode, isUserValidated, latitude, longitude) {
  return {
    franchiseId: franchiseId,
    phonePrimary: phonePrimary,
    satelliteOfficeName: satelliteOfficeName,
    beginDateTime: beginDateTime,
    modifiedBy: modifiedBy,
    googleReviewUrl: googleReviewUrl,
    validatedAddressDto: {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      stateCode: stateCode,
      postalCode: postalCode,
      countryCode: countryCode,
      isUserValidated: isUserValidated,
      latitude: latitude,
      longitude: longitude
    }
  }
}

function enrollFranchiseDto(
  operatingUnitId,
  primaryPhone,
  dialpadPhone,
  primaryEmail,
  franchiseName,
  franchiseNumber,
  isMiniMarket,
  isRoyaltyExempt,
  systemAccessDate,
  businessStartDate,
  googleReviewUrl,
  modifiedBy,
  addressLine1,
  addressLine2,
  city,
  stateCode,
  postalCode,
  countryCode,
  isUserValidated,
  latitude,
  longitude
) {
  return {
    operatingUnitId: operatingUnitId,
    phone: primaryPhone,
    dialpadPhone: dialpadPhone,
    email: primaryEmail,
    franchiseName: franchiseName,
    franchiseNumber: franchiseNumber,
    isMiniMarket: isMiniMarket,
    isRoyaltyExempt: isRoyaltyExempt,
    systemAccessDate: systemAccessDate,
    businessStartDate: businessStartDate,
    googleReviewUrl: googleReviewUrl,
    modifiedBy: modifiedBy,
    validatedAddressDto: {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      stateCode: stateCode,
      postalCode: postalCode,
      countryCode: countryCode,
      isUserValidated: isUserValidated,
      latitude: latitude,
      longitude: longitude
    }
  }
}

function createFranchiseTaxDto(franchises, name, rate, beginDate, createdBy) {
  return {
    franchiseIds: franchises,
    name: name,
    rate: rate,
    beginDate: beginDate,
    createdBy: createdBy
  }
}

function associateTaxToFranchisesDto(franchiseIds, franchiseTaxId, createdBy) {
  return {
    franchiseIds: franchiseIds,
    franchiseTaxId: franchiseTaxId,
    createdBy: createdBy
  }
}

function putFranchiseTaxEndDateDto(franchiseTaxId, endDate) {
  return {
    franchiseTaxId: franchiseTaxId,
    endDate: endDate
  }
}

function putCustomerDetailsDto(customerId, customerTypeId, businessName, isTaxExempt, employerIdentificationNumber, modifiedBy) {
  return {
    customerId: customerId,
    customerTypeId: customerTypeId,
    businessName: businessName,
    isTaxExempt: isTaxExempt,
    employerIdentificationNumber: employerIdentificationNumber,
    modifiedBy: modifiedBy
  }
}

function createEmployeeDto(firstName, lastName, mobilePhoneNumber, emailAddress) {
  return {
    firstName: firstName,
    lastName: lastName,
    email: emailAddress,
    phoneNumber: mobilePhoneNumber
  }
}

function upsertEmployeeDetailsDto(id, firstName, lastName, emailAddress) {
  return {
    id: id,
    firstName: firstName,
    lastName: lastName,
    email: emailAddress
  }
}

function putJunkTruckWeeklyAllocationDto(junkTruckWeeklyAllocationId, amSlots, pmSlots, dispatchLocationId, createdBy) {
  return {
    junkTruckWeeklyAllocationId: junkTruckWeeklyAllocationId,
    amSlots: amSlots,
    pmSlots: pmSlots,
    dispatchLocationId: dispatchLocationId,
    createdBy: createdBy
  }
}

function putFranchiseBusinessHoursDto(franchiseBusinessHourId, isOfficeClosed, isHaulingClosed, officeOpenTime, officeCloseTime, customerBookingAmCutoffTime, customerBookingPmCutoffTime) {
  return {
    franchiseBusinessHourId: franchiseBusinessHourId,
    isOfficeClosed: isOfficeClosed,
    isHaulingClosed: isHaulingClosed,
    officeOpenTime: officeOpenTime,
    officeCloseTime: officeCloseTime,
    customerBookingAmCutoffTime: customerBookingAmCutoffTime,
    customerBookingPmCutoffTime: customerBookingPmCutoffTime
  }
}

function putFranchiseSpecialHoursDto(franchiseSpecialHourId, description, overriddenDate, isOfficeClosed, isHaulingClosed, officeOpenTime, officeCloseTime, customerBookingAmCutoffTime, customerBookingPmCutoffTime) {
  return {
    franchiseSpecialHourId: franchiseSpecialHourId,
    description: description,
    overriddenDate: overriddenDate,
    isOfficeClosed: isOfficeClosed,
    isHaulingClosed: isHaulingClosed,
    officeOpenTime: officeOpenTime,
    officeCloseTime: officeCloseTime,
    customerBookingAmCutoffTime: customerBookingAmCutoffTime,
    customerBookingPmCutoffTime: customerBookingPmCutoffTime
  }
}

function postFranchiseSpecialHoursDto(franchiseId, description, overriddenDate, isOfficeClosed, isHaulingClosed, officeOpenTime, officeCloseTime, customerBookingAmCutoffTime, customerBookingPmCutoffTime) {
  return {
    franchiseId: franchiseId,
    description: description,
    date: overriddenDate,
    isOfficeClosed: isOfficeClosed,
    isHaulingClosed: isHaulingClosed,
    officeOpenTime: officeOpenTime,
    officeCloseTime: officeCloseTime,
    customerBookingAmCutoffTime: customerBookingAmCutoffTime,
    customerBookingPmCutoffTime: customerBookingPmCutoffTime
  }
}

function putFranchiseCustomerSelfBookingCutoffDto(franchiseId, selfBookingCutOffDistance) {
  return {
    franchiseId: franchiseId,
    selfBookingCutOffDistance: selfBookingCutOffDistance
  }
}

function postOperatingUnitAllocationGroupDto(groupName, franchiseIds) {
  return {
    groupName: groupName,
    franchiseIds: franchiseIds
  }
}

function putOperatingUnitAllocationGroupDto(operatingUnitAllocationGroupId, groupName) {
  return {
    operatingUnitAllocationGroupId: operatingUnitAllocationGroupId,
    groupName: groupName
  }
}

function createFranchiseOperatingUnitAllocationGroupDto(operatingUnitAllocationGroupId, franchiseIds) {
  return {
    operatingUnitAllocationGroupId: operatingUnitAllocationGroupId,
    franchiseIds: franchiseIds
  }
}

function createNationalDiscountDto(discountName, discountPromoCode, discountAmount, isPercentage, beginDate, endDate, buttonPhraseText, createdBy) {
  return {
    discountName: discountName,
    promoCode: discountPromoCode,
    discountAmount: discountAmount,
    isPercentage: isPercentage,
    beginDate: beginDate,
    endDate: endDate,
    defaultWebsitePhrase: buttonPhraseText,
    createdBy: createdBy
  }
}

function createOperatingUnitDiscountDto(discountName, discountPromoCode, discountAmount, isPercentage, operatingUnitId, beginDate, endDate, createdBy, franchiseIds) {
  return {
    discountName: discountName,
    promoCode: discountPromoCode,
    discountAmount: discountAmount,
    isPercentage: isPercentage,
    operatingUnitId: operatingUnitId,
    beginDate: beginDate,
    endDate: endDate,
    createdBy: createdBy,
    franchiseElectedDiscounts: franchiseIds
  }
}

function createOperatingUnitFranchiseElectedDiscountDto(franchiseId, beginDate, endDate) {
  return {
    franchiseId: franchiseId,
    beginDate: beginDate,
    endDate: endDate
  }
}

function updateNationalDiscountDto(nationalDiscountId, beginDate, endDate, modifiedBy, promoCode) {
  return {
    nationalDiscountId: nationalDiscountId,
    beginDate: beginDate,
    endDate: endDate,
    modifiedBy: modifiedBy,
    promoCode: promoCode
  }
}

function updateOperatingUnitDiscountDto(operatingUnitDiscountId, beginDate, endDate, promoCode, modifiedBy) {
  return {
    operatingUnitDiscountId: operatingUnitDiscountId,
    beginDate: beginDate,
    endDate: endDate,
    promoCode: promoCode,
    modifiedBy: modifiedBy
  }
}

function createNationalDiscountElectedFranchisesDto(nationalDiscountId, franchiseIds, createdBy) {
  return {
    nationalDiscountId: nationalDiscountId,
    franchiseIds: franchiseIds,
    createdBy: createdBy
  }
}

function createOperatingUnitDiscountElectedFranchisesDto(operatingUnitDiscountId, franchiseIds, beginDate, endDate, createdBy) {
  return {
    operatingUnitDiscountId: operatingUnitDiscountId,
    franchiseIds: franchiseIds,
    beginDate: beginDate,
    endDate: endDate,
    createdBy: createdBy
  }
}

function updateFranchiseElectedDiscountDto(franchiseElectedDiscountId, beginDate, endDate, modifiedBy) {
  return {
    franchiseElectedDiscountId: franchiseElectedDiscountId,
    beginDate: beginDate,
    endDate: endDate,
    modifiedBy: modifiedBy
  }
}

function createFranchiseElectedDiscountForWebsiteDto(franchiseElectedDiscountId, displayBeginDate, displayEndDate, createdBy) {
  return {
    franchiseElectedDiscountId: franchiseElectedDiscountId,
    websiteDisplayBeginDate: displayBeginDate,
    websiteDisplayEndDate: displayEndDate,
    createdBy: createdBy
  }
}

function updateFranchiseElectedDiscountForWebsiteDto(franchiseElectedDiscountForWebsiteId, websiteDisplayBeginDate, websiteDisplayEndDate, modifiedBy) {
  return {
    franchiseElectedDiscountForWebsiteId: franchiseElectedDiscountForWebsiteId,
    websiteDisplayBeginDate: websiteDisplayBeginDate,
    websiteDisplayEndDate: websiteDisplayEndDate,
    modifiedBy: modifiedBy
  }
}

function putJobDiscountDto(jobId, franchiseElectedDiscountId, modifiedBy) {
  return {
    jobId: jobId,
    franchiseElectedDiscountId: franchiseElectedDiscountId,
    modifiedBy: modifiedBy
  }
}

function putLeadSourceDto(jobId, leadSourceId, modifiedBy) {
  return {
    jobId: jobId,
    leadSourceId: leadSourceId,
    modifiedBy: modifiedBy
  }
}

function previewJobDataFixDto(jobId, franchiseTaxId, jobStatusId, cancellationReasonId, price, capacityId, currentUserId, currentRequester) {
  return {
    jobId: jobId,
    franchiseTaxId: franchiseTaxId,
    jobStatusId: jobStatusId,
    cancellationReasonId: cancellationReasonId,
    price: price,
    capacityId: capacityId,
    createdBy: currentUserId,
    requestedByEmployeeId: currentRequester.id,
    requestedByEmployeeFirstName: currentRequester.givenName,
    requestedByEmployeeLastName: currentRequester.surname
  }
}

function resendConfirmationEmailDto(employeeId) {
  return {
    employeeId: employeeId
  }
}

function putJunkJobSingleItemDto(jobId, singleItem, modifiedBy) {
  return {
    jobId: jobId,
    isSingleItem: singleItem,
    modifiedBy: modifiedBy
  }
}

function jobLeadPutDto(jobId, isHandled, employeeId) {
  return {
    jobId: jobId,
    isHandled: isHandled,
    handledBy: employeeId
  }
}

function postJobLeadDto(jobId, employeeId) {
  return {
    jobId: jobId,
    handledBy: employeeId
  }
}

function upsertTipConfigurationDto(franchiseId, isEnabledTipConfiguration, isEnabledTipOptions, isPercentage, tipOptionOneAmount, tipOptionTwoAmount, tipOptionThreeAmount, modifiedBy) {
  return {
    franchiseId: franchiseId,
    isEnabled: isEnabledTipConfiguration,
    isTipOptionsEnabled: isEnabledTipOptions,
    isTipOptionsPercentage: isPercentage,
    tipOptionOneAmount: tipOptionOneAmount,
    tipOptionTwoAmount: tipOptionTwoAmount,
    tipOptionThreeAmount: tipOptionThreeAmount,
    modifiedBy: modifiedBy
  }
}

function postCustomerTextBookingConfirmationDto(jobId, employeeId) {
  return {
    jobId: jobId,
    employeeId: employeeId
  }
}

function postCustomerTextEtaDto(jobId, eta, createdBy) {
  return {
    jobId: jobId,
    eta: eta,
    createdBy: createdBy
  }
}

function postCustomerTextJobStatementDto(jobId, statementUrl, employeeId) {
  return {
    jobId: jobId,
    statementUrl: statementUrl,
    employeeId: employeeId,
    shouldSendGoogleReview: false
  }
}

module.exports = {
  abandonJobDto,
  associateTaxToFranchisesDto,
  cancelJobDto,
  createEmployeeDto,
  createFranchiseTaxDto,
  claimJobDto,
  closeJobDto,
  completeJobDto,
  disclaimJobDto,
  createCustomerDto,
  createCustomerAddressDto,
  createCustomerContactDto,
  createJobNoteDto,
  createJobEmployeeDto,
  createJobTruckDto,
  customerSearchDto,
  createOperatingUnitDto,
  createSatelliteOfficeDto,
  enrollFranchiseDto,
  jobLeadPutDto,
  putCustomerDetailsDto,
  putJobEmployeeIsDriverDto,
  putJunkJobTruckStartDateTimeDto,
  putJunkJobTruckEndDateTimeDto,
  postFranchiseSpecialHoursDto,
  updateJunkJobTruckDownTimeDto,
  fetchScheduleSlotDto,
  putCustomerAddressDto,
  putCustomerAddressUserValidatedDto,
  putFranchiseBusinessHoursDto,
  putFranchiseCustomerSelfBookingCutoffDto,
  putFranchiseSpecialHoursDto,
  postJobEmployeeIndirectLaborDto,
  putFranchiseDetailsWithUpdatedAddressDto,
  putJunkJobDto,
  putFranchiseTaxEndDateDto,
  putJunkJobSatelliteOfficeDto,
  putJobPriceDto,
  putJunkJobEmployeeTipDto,
  putJobPreferredTimeSlotDto,
  putJobSelectedFranchiseTaxDto,
  putOperatingUnitDto,
  putFranchiseDetailsDto,
  putSatelliteOfficeDetailsDto,
  putSatelliteOfficeDetailsWithUpdatedAddressDto,
  scheduleJobDto,
  updateTrucksAndEmployeeGlobalTimesDto,
  uncompleteJobDto,
  upsertEmployeeDetailsDto,
  putJunkTruckWeeklyAllocationDto,
  postOperatingUnitAllocationGroupDto,
  postJobLeadDto,
  putOperatingUnitAllocationGroupDto,
  createFranchiseOperatingUnitAllocationGroupDto,
  createNationalDiscountDto,
  createOperatingUnitDiscountDto,
  createOperatingUnitFranchiseElectedDiscountDto,
  updateNationalDiscountDto,
  updateOperatingUnitDiscountDto,
  createNationalDiscountElectedFranchisesDto,
  createOperatingUnitDiscountElectedFranchisesDto,
  updateFranchiseElectedDiscountDto,
  createFranchiseElectedDiscountForWebsiteDto,
  updateFranchiseElectedDiscountForWebsiteDto,
  putJobDiscountDto,
  putLeadSourceDto,
  previewJobDataFixDto,
  resendConfirmationEmailDto,
  putJunkJobSingleItemDto,
  upsertTipConfigurationDto,
  postCustomerTextEtaDto,
  postCustomerTextBookingConfirmationDto,
  postCustomerTextJobStatementDto
}
