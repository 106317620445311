<template>
  <v-container fluid class="mb-16 mt-1 px-4">
    <mgt-get
      v-if="triggerFetchUsersInOperatingUnitQuery"
      :resource="getUsersInOperatingUnitQuery"
      scopes="User.Read.All,Group.Read.All"
      version="v1.0"
      cache-enabled="true"
      @dataChange="onEmployeeDataChanged($event)"
    >
    </mgt-get>

    <v-row>
      <v-col>
        <app-status-stepper-card ref="job-status-stepper-card" />
      </v-col>
    </v-row>

    <job-off-canvas-blade v-if="isJobDocumentBladeVisible"></job-off-canvas-blade>

    <v-row class="justify-center justify-space-between flex-fill">
      <v-col :class="customerDetailsCardClass">
        <job-customer-card
          ref="job-customer-card"
          @promptCreateCustomerAddressDialog="setIsAddAddressDialogVisible(true)"
          @promptDialog="setIsEditContactDialogVisible(true, $event)"
          @deleteContact="setIsDeleteContactDialogVisible($event, true)"
          @deleteAddress="setIsDeleteAddressDialogVisible($event, true)"
          @isContactOrAddressCardVisible="setIsContactOrAddressCardVisible"
        />
      </v-col>
      <v-col>
        <job-customer-communications-card
            class="flex-column d-flex flex-fill fill-height"
            @handle-send="handleSetupSendCustomerCommunicationDialog"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="6" class="flex-column d-flex">
        <job-details-card
          ref="job-details-card"
          @updatePreferredTimeOfDay="setOverSlotAllocationWarningDialogOptions(true, $event.date, $event.timeSlot, $event.positiveAction, $event.cancellationAction)"
          @updateScheduledDate="setOverSlotAllocationWarningDialogOptions(true, $event.date, $event.timeSlot, $event.positiveAction, $event.cancellationAction)"
          @lead-source-updated="putJobLeadSource"
        />
      </v-col>
      <v-col class="flex-column d-flex">
        <job-franchise-selector-card ref="job-franchise-selector-card" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          v-if="getIsLoadingJobAddressesReorder"
          ref="job-addresses-card-skeleton-loader"
          type="image"
          transition="scale-transition"
          min-height="300"
          max-height="800"
          elevation="4"
          :loading="getIsLoadingJobAddressesReorder"
        />
        <job-addresses-card v-else ref="job-addresses-card" @promptCustomerAddressDialog="setIsAddAddressDialogVisible($event)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="9">
        <job-truck-capacity-card ref="job-truck-capacity-card" />
      </v-col>
      <v-col cols="12" lg="3">
        <job-modifiers-card ref="job-modifiers-card" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <job-notes ref="job-notes-card" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <job-trucks-and-employees-card ref="job-trucks-and-employees-card" />
      </v-col>
    </v-row>

    <v-layout row class="mt-16">
      <job-footer
        ref="job-footer"
        :is-expandable-button-disabled="getIsJobFieldsDisabled"
        @scheduleJob="promptScheduledDateInPastIsOverSlotAllocationDialogOrScheduleJob"
        @abandonJob="setIsJobAbandonmentReasonDialogVisible(true)"
        @cancelJob="setIsJobCancellationReasonDialogVisible(true)"
        @claimJob="setIsJobClaimJobConfirmationDialogVisible(true)"
        @closeJob="closeJob"
        @completeJob="completeJobHandler"
        @disclaimJob="disclaimJob"
        @uncompleteJob="uncompleteJob"
      />
    </v-layout>

    <base-fab ref="job-fab" class="mb-n2 me-n2" :is-visible="true" :fab-options="fabOptions" />

    <app-edit-contact-dialog
      v-if="isEditContactDialogVisible"
      :is-dialog-visible="isEditContactDialogVisible"
      :contact="selectedContact"
      :is-loading="getIsLoadingEditContact"
      :franchise-id="getCurrentJob.franchiseId"
      @closeDialog="setIsEditContactDialogVisible(false, {})"
      @editContact="updateContact"
    />

    <app-confirmation-dialog
      v-if="isDeleteContactDialogVisible"
      :is-dialog-visible="isDeleteContactDialogVisible"
      :is-loading="getIsLoadingDeletingContact"
      title="Delete Contact?"
      :content-text="`Are you sure you want to delete ${selectedContact.firstName} ${selectedContact.lastName}?`"
      dialog-image="mdi-account-cancel-outline"
      @closeDialog="setIsDeleteContactDialogVisible(undefined, false)"
      @confirmed="handleDeleteContact"
    />

    <app-confirmation-dialog
      v-if="isDeleteAddressDialogVisible"
      :is-dialog-visible="isDeleteAddressDialogVisible"
      :is-loading="getIsLoadingDeleteCustomerAddress"
      title="Delete Address?"
      :content-text="`Are you sure you want to delete ${formatCustomerAddressItemText(this.selectedAddress)}?`"
      dialog-image="mdi-map-marker-minus"
      @closeDialog="setIsDeleteAddressDialogVisible(undefined, false)"
      @confirmed="handleDeleteAddress"
    />

    <app-full-screen-progress-bar-circular
      v-if="getIsLoadingJobFullScreen.isLoading"
      ref="job-full-screen-progress-bar-circular"
      :loading-text="getIsLoadingJobFullScreen.loadingText"
      :is-enabled="getIsLoadingJobFullScreen.isLoading"
      :is-indeterminate-loading-state="getIsLoadingJobFullScreen.isLoading"
    />

    <job-add-customer-address-dialog
      v-if="isAddAddressDialogVisible"
      ref="job-add-customer-address-dialog"
      :is-dialog-visible="isAddAddressDialogVisible"
      @closeDialog="setIsAddAddressDialogVisible(false)"
      @isAddressSearchDialogVisible="setIsAddressSearchDialogVisible(true)"
    />

    <app-address-search-dialog
      v-if="isAddressSearchDialogVisible"
      ref="address-search-dialog"
      :is-dialog-visible="isAddressSearchDialogVisible"
      :initial-camera-position="{ lat: getSelectedFranchiseLatitudeAndLongitudeOrDefault.latitude, lng: getSelectedFranchiseLatitudeAndLongitudeOrDefault.longitude }"
      @closeDialog="setIsAddressSearchDialogVisible(false)"
      @createAddressFromSearch="createAddressAndCustomerAddress($event)"
      :is-loading-create-address-from-search="getIsLoadingJobAddresses"
      @createAddressFromForm="createAddressAndCustomerAddress($event)"
      :is-loading-app-new-address-form-dialog="getIsLoadingJobAddresses"
    />

    <job-send-customer-communication-dialog
      ref="job-send-customer-communication-dialog"
      v-if="customerCommunicationsProps.isDialogVisible"
      :is-dialog-visible="customerCommunicationsProps.isDialogVisible"
      :communication-type="customerCommunicationsProps.communicationType"
      :icon="customerCommunicationsProps.icon"
      :message="customerCommunicationsProps.message"
      :confirm-button-text="customerCommunicationsProps.confirmButtonText"
      @handle-send-booking-confirmation="handleSendBookingConfirmation"
      @handle-send-driver-eta="handleSendDriverEta"
      @handle-send-job-statement="handleSendJobStatement"
      @close-dialog="resetCustomerCommunicationsProps"
    />

    <job-add-trucks-and-employees-dialog
      ref="job-add-trucks-and-employees-dialog"
      v-if="isAddTrucksAndEmployeesDialogVisible"
      :is-dialog-visible="isAddTrucksAndEmployeesDialogVisible"
      @closeDialog="setIsAddTrucksAndEmployeesDialogVisible(false)"
    />

    <app-over-allocation-confirmation-dialog
      ref="app-over-allocation-confirmation-dialog"
      v-if="overAllocationConfirmationDialogOptions.isVisible"
      :is-dialog-visible="overAllocationConfirmationDialogOptions.isVisible"
      :scheduled-date="overAllocationConfirmationDialogOptions.scheduledDate"
      :time-slot-name="overAllocationConfirmationDialogOptions.preferredTimeSlotName"
      @confirm="overAllocationConfirmationDialogOptions.confirmationAction"
      @closeDialog="overAllocationConfirmationDialogOptions.cancellationAction"
    />

    <job-cancellation-reason-dialog
      ref="job-cancellation-reason-dialog"
      v-if="isJobCancellationReasonDialogVisible"
      :is-dialog-visible="isJobCancellationReasonDialogVisible"
      @closeDialog="setIsJobCancellationReasonDialogVisible(false)"
      @confirmDialog="cancelJob"
    />

    <job-abandonment-reason-dialog
      ref="job-abandonment-reason-dialog"
      v-if="isJobAbandonmentReasonDialogVisible"
      :is-dialog-visible="isJobAbandonmentReasonDialogVisible"
      @closeDialog="setIsJobAbandonmentReasonDialogVisible(false)"
      @confirmDialog="abandonJob"
    />

    <claim-job-confirmation-dialog
      ref="job-claim-job-confirmation-dialog"
      v-if="isJobClaimJobConfirmationDialogVisible"
      :is-dialog-visible="isJobClaimJobConfirmationDialogVisible"
      :title="`Claim ${getCurrentJob.jobNumber}?`"
      :employees-on-job="getEmployeesOnJob"
      :trucks-on-job="getTrucksOnJob"
      :employees-not-on-job="getActiveEmployeesNotOnJob"
      :trucks-not-on-job="getActiveTrucksNotOnJob"
      @confirm="claimJob($event.employeeIds, $event.truckIds, $event.drivers)"
      @closeDialog="setIsJobClaimJobConfirmationDialogVisible(false)"
    />

    <app-create-contact-dialog
      v-if="isCreateCustomerContactDialogVisible"
      :customer-id="getCurrentJob.customerId"
      :is-loading="getIsLoadingCreateContact"
      :is-dialog-visible="isCreateCustomerContactDialogVisible"
      :franchise-id="getCurrentJob.franchiseId"
      @closeDialog="setIsCreateCustomerContactDialogVisible(false)"
      @confirm="createCustomerContact"
    />

    <job-indirect-labor-dialog
      ref="job-indirect-labor-dialog"
      v-if="isJobIndirectLaborDialogVisible"
      :is-dialog-visible="isJobIndirectLaborDialogVisible"
      @closeDialog="setIsJobIndirectLaborDialogVisible(false)"
    />

    <app-confirmation-dialog
      v-if="isPromptScheduledDateInPastDialogVisible"
      :is-dialog-visible="isPromptScheduledDateInPastDialogVisible"
      :is-loading="false"
      title="Continue Scheduling Job?"
      :content-text="
        `You're attempting to schedule a job with a Scheduled Date in the past, ${getFormattedServiceDateAsDayOfWeekMonthDayYear}. Are you sure you want to continue scheduling?`
      "
      dialog-image="mdi-help"
      @closeDialog="setIsScheduledDateInPastDialogVisible(false)"
      @confirmed="scheduledDateInPastDialogConfirmationHandler"
    />

    <app-confirmation-dialog
      v-if="isReceiptNotCompletedWarningDialogVisible"
      :is-dialog-visible="isReceiptNotCompletedWarningDialogVisible"
      :is-loading="getIsLoadingJobDocuments"
      title="Statement Not Completed"
      content-text="You are attempting to complete a job without generating a statement. If you proceed, a blank statement will be generated and sent to the customer, if an email exists. Are you sure you want to continue?"
      dialog-image="mdi-alert"
      @closeDialog="setIsReceiptNotCompletedWarningDialogVisible(false)"
      @confirmed="completeJobAndGenerateReceipt"
    />
  </v-container>
</template>

<script>
import JobCustomerCard from "@/components/JobCustomerCard"
import { mapActions, mapGetters } from "vuex"
import AppStatusStepperCard from "@/components/AppStatusStepperCard"
import BaseFab from "@/components/BaseFab"
import AppFullScreenProgressBarCircular from "@/components/AppFullScreenProgressBarCircular"
import JobNotes from "@/components/JobNotesCard"
import JobTrucksAndEmployeesCard from "@/components/JobTrucksAndEmployeesCard"
import JobDetailsCard from "@/components/JobDetailsCard"
import JobAddCustomerAddressDialog from "@/components/JobAddCustomerAddressDialog"
import JobTruckCapacityCard from "@/components/JobTruckCapacityCard"
import JobAddressesCard from "@/components/JobAddressesCard"
import JobFranchiseSelectorCard from "@/components/JobFranchiseSelectorCard"
import AppAddressSearchDialog from "@/components/AppAddressSearchDialog"
import {
  formatAsDateOnly,
  formatTimeAsDateUtc,
  getIsFirstDateBeforeSecondDate,
  getLastDayOfMonthByYearAndMonth,
  getMinimumDateOrFirstOfMonth,
  getYearAndMonthFromDateTime,
  todayAsDate
} from "@/utils/DateTimeFormatters"
import JobAddTrucksAndEmployeesDialog from "@/components/JobAddTrucksAndEmployeesDialog"
import {
  abandonJobDto,
  cancelJobDto,
  claimJobDto,
  closeJobDto,
  completeJobDto,
  disclaimJobDto,
  fetchScheduleSlotDto,
  scheduleJobDto,
  uncompleteJobDto,
  putLeadSourceDto,
  resendConfirmationEmailDto,
  postCustomerTextEtaDto,
  postCustomerTextBookingConfirmationDto,
  postCustomerTextJobStatementDto
} from "@/api/dtos/JunkDtos"
import AppOverAllocationConfirmationDialog from "@/components/AppOverAllocationConfirmationDialog"
import JobFooter from "@/components/JobFooter"
import JobAbandonmentReasonDialog from "@/components/JobAbandonmentReasonDialog"
import JobCancellationReasonDialog from "@/components/JobCancellationReasonDialog"
import ClaimJobConfirmationDialog from "@/components/ClaimJobConfirmationDialog"
import JobIndirectLaborDialog from "@/components/JobIndirectLaborDialog"
import router from "@/router"
import { errorSnackbar } from "@/utils/SnackbarBuilder"
import AppEditContactDialog from "@/components/AppEditContactDialog"
import { formatAddressText } from "@/utils/FormatAddressText"
import AppConfirmationDialog from "@/components/AppConfirmationDialog"
import AppCreateContactDialog from "@/components/AppCreateContactDialog"
import JobModifiersCard from "@/components/JobModifiersCard"
import { EnvironmentVariant } from "@/enums/Environments"
import JobOffCanvasBlade from "@/components/JobOffCanvasBlade"
import { v4 } from "uuid"
import JobCustomerCommunicationsCard from "@/components/JobCustomerCommunicationsCard"
import { createReceiptDto, generateReceiptDto, jobFranchiseDocumentRecipientDto } from "@/api/dtos/DocumentDtos"
import JobSendCustomerCommunicationDialog from "@/components/JobSendCustomerCommunicationDialog"
import { CustomerCommunications } from "@/enums/CustomerCommunications"

export default {
  name: "Job",
  components: {
    JobSendCustomerCommunicationDialog,
    JobCustomerCommunicationsCard,
    JobOffCanvasBlade,
    AppCreateContactDialog,
    AppConfirmationDialog,
    AppEditContactDialog,
    JobIndirectLaborDialog,
    ClaimJobConfirmationDialog,
    JobAbandonmentReasonDialog,
    JobCancellationReasonDialog,
    JobFooter,
    AppOverAllocationConfirmationDialog,
    JobAddTrucksAndEmployeesDialog,
    JobFranchiseSelectorCard,
    JobAddressesCard,
    JobModifiersCard,
    JobTruckCapacityCard,
    JobAddCustomerAddressDialog,
    JobDetailsCard,
    JobTrucksAndEmployeesCard,
    JobNotes,
    AppFullScreenProgressBarCircular,
    BaseFab,
    AppStatusStepperCard,
    JobCustomerCard,
    AppAddressSearchDialog
  },
  data() {
    return {
      jobIdFromUrl: undefined,
      isContactOrAddressCardVisible: false,
      selectedContact: {},
      selectedAddress: {},
      isCreateCustomerContactDialogVisible: false,
      isDeleteContactDialogVisible: false,
      isEditContactDialogVisible: false,
      isAddTrucksAndEmployeesDialogVisible: false,
      isAddAddressDialogVisible: false,
      isAddressSearchDialogVisible: false,
      isDeleteAddressDialogVisible: false,
      customerCommunicationsProps: {
        isDialogVisible: false,
        communicationType: 0,
        icon: '',
        message: '',
        confirmButtonText: '',
      },
      isJobAbandonmentReasonDialogVisible: false,
      isJobCancellationReasonDialogVisible: false,
      isJobClaimJobConfirmationDialogVisible: false,
      isJobIndirectLaborDialogVisible: false,
      isPromptScheduledDateInPastDialogVisible: false,
      isReceiptNotCompletedWarningDialogVisible: false,
      overAllocationConfirmationDialogOptions: {
        isVisible: false,
        scheduledDate: "",
        preferredTimeSlotName: "",
        confirmationAction: undefined,
        cancellationAction: undefined
      }
    }
  },
  computed: {
    ...mapGetters(["getUserOperatingUnitIds", "getAppEnvironment"]),
    ...mapGetters("Job", [
      "getCurrentJob",
      "getCurrentJobScheduledDateOrToday",
      "getIsLoadingAnyJobComponents",
      "getPrimaryContactDetails",
      "getIsLoadingEditContact",
      "getIsLoadingJobAddressesReorder",
      "getFormattedServiceDateAsDayOfWeekMonthDayYear",
      "getIsLoadingJobFullScreen",
      "getIsJobFieldsDisabled",
      "getIsIndirectLaborFabDisabled",
      "getEmployeesOnJob",
      "getTrucksOnJob",
      "getActiveEmployeesNotOnJob",
      "getActiveTrucksNotOnJob",
      "getSelectedFranchise",
      "getIsLoadingDeletingContact",
      "getIsLoadingDeleteCustomerAddress",
      "getIsLoadingCreateContact",
      "getIsLoadingJobAddresses",
      "getJobSelectedOperatingUnitId",
      "getIsLoadingJob",
      "getJobDocumentReceiptInfo",
      "getSelectedEmailReceiptRecipients",
      "getIsJobStatusClaimed",
      "getIsJobStatusCompleted",
      "getIsJobStatusClosed",
      "getIsLoadingJobDocuments"
    ]),
    customerDetailsCardClass() {
      return this.isContactOrAddressCardVisible ? "flex-column d-flex flex-fill" : "flex-column d-flex"
    },
    triggerFetchUsersInOperatingUnitQuery() {
      return this.getJobSelectedOperatingUnitId !== undefined && this.getJobSelectedOperatingUnitId !== null
    },
    getUsersInOperatingUnitQuery() {
      return `/groups/${this.getJobSelectedOperatingUnitId}/members/microsoft.graph.user?$expand=memberOf&$count=true`
    },
    isActionsDisabled() {
      return this.getIsLoadingAnyJobComponents || this.getIsJobFieldsDisabled
    },
    getSelectedFranchiseLatitudeAndLongitudeOrDefault() {
      return { latitude: this.getSelectedFranchise?.franchiseAddressLatitude ?? 0.0, longitude: this.getSelectedFranchise?.franchiseAddressLongitude ?? 0.0 }
    },
    fabOptions() {
      return [
        {
          icon: "mdi-map-marker-plus-outline",
          onClickAction: () => this.setIsAddAddressDialogVisible(true),
          text: "Add Customer Address",
          isFabDisabled: this.isActionsDisabled
        },
        {
          icon: "mdi-account-plus-outline",
          onClickAction: () => this.setIsCreateCustomerContactDialogVisible(true),
          text: "Create Contact",
          isFabDisabled: this.getIsLoadingJob
        },
        {
          icon: "mdi-ambulance",
          onClickAction: () => this.setIsAddTrucksAndEmployeesDialogVisible(true),
          text: "Add Trucks & Employees",
          isFabDisabled: this.isActionsDisabled
        },
        {
          icon: "mdi-account-hard-hat-outline",
          onClickAction: () => this.setIsJobIndirectLaborDialogVisible(true),
          text: "Add Indirect Labor",
          isFabDisabled: this.getIsIndirectLaborFabDisabled
        }
      ]
    },
    isJobDocumentBladeVisible() {
      return this.getIsJobStatusClaimed || this.getIsJobStatusCompleted || this.getIsJobStatusClosed
    },
    hasReceipt() {
      return Object.keys(this.getJobDocumentReceiptInfo ?? {}).length !== 0
    }
  },
  methods: {
    ...mapActions(["addSnackbar"]),
    ...mapActions("Job", [
      "fetchJunkJobByJobIdInitial",
      "fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits",
      "fetchCustomerContactsAndAddresses",
      "fetchActiveFranchiseCapacitiesByFranchiseIdAndDate",
      "fetchScheduleSlotAllocations",
      "scheduleJunkJobByJobId",
      "fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId",
      "fetchJunkJobNotes",
      "fetchJunkJobAddresses",
      "fetchJunkJobTrucks",
      "fetchJunkJobEmployees",
      "fetchActiveFranchiseTaxes",
      "fetchActiveJunkTrucksInOperatingUnitByDate",
      "fetchJobCompletionStatuses",
      "abandonJunkJobByJobId",
      "cancelJunkJobByJobId",
      "closeJunkJobByJobId",
      "claimJunkJobByJobId",
      "completeJunkJobByJobId",
      "createAddressRecords",
      "disclaimJunkJobByJobId",
      "setAllJobComponentsLoadingState",
      "editContact",
      "deleteContact",
      "deleteCustomerAddress",
      "createContact",
      "fetchTimeSlots",
      "setEmployeesInJobOperatingUnit",
      "addJunkJobAddresses",
      "uncompleteJunkJobByJobId",
      "putLeadSource",
      "fetchLeadSources",
      "fetchJobReceipt",
      "createJobReceipt",
      "generateJobReceipt",
      "resendJobReceipt",
      "setSelectedEmailReceiptRecipients",
      "sendCustomerTextBookingConfirmation",
      "sendCustomerTextEta",
      "sendCustomerTextJobStatement",
      "resendConfirmationEmail",
      "clearJobState",
      "fetchApplicableDiscounts"
    ]),
    async onEmployeeDataChanged(event) {
      let data = event?.detail?.response?.value ?? []
      let rolesToRemove = ["corporate admin", "sales support"]

      if (this.getAppEnvironment !== EnvironmentVariant.PRODUCTION) {
        rolesToRemove = rolesToRemove.map(role => `${role} (${this.getAppEnvironment})`)
      }
      data = data.filter(item => !item.memberOf.some(group => rolesToRemove.some(role => group?.displayName?.toLowerCase() === role)))

      await this.setEmployeesInJobOperatingUnit(data)
    },
    async createCustomerContact(contact) {
      await this.createContact(contact).then(() => this.setIsCreateCustomerContactDialogVisible(false))
    },
    setIsCreateCustomerContactDialogVisible(isVisible) {
      this.isCreateCustomerContactDialogVisible = isVisible
    },
    setIsContactOrAddressCardVisible(isVisible) {
      this.isContactOrAddressCardVisible = isVisible
    },
    setIsDeleteContactDialogVisible(contact, isVisible) {
      this.setSelectedContact(contact)
      this.isDeleteContactDialogVisible = isVisible
    },
    setSelectedContact(contact) {
      this.selectedContact = contact
    },
    handleDeleteContact() {
      this.deleteContact(this.selectedContact.id).then(() => {
        this.setIsDeleteContactDialogVisible({}, false)
      })
    },
    formatCustomerAddressItemText(item) {
      return formatAddressText(item)
    },
    setIsDeleteAddressDialogVisible(address, isVisible) {
      this.setSelectedAddress(address)
      this.isDeleteAddressDialogVisible = isVisible
    },
    handleSetupSendCustomerCommunicationDialog(communicationType) {
      switch (communicationType) {
        case CustomerCommunications.BOOKING_CONFIRMATION.id: {
          this.customerCommunicationsProps = {
            isDialogVisible: true,
            communicationType: communicationType,
            icon: 'mdi-calendar-check',
            message: 'Send Confirmation?',
            confirmButtonText: 'Confirm'
          }
          break
        }
        case CustomerCommunications.DRIVER_ETA.id: {
          this.customerCommunicationsProps = {
            isDialogVisible: true,
            communicationType: communicationType,
            icon: 'mdi-clock-time-three-outline',
            message: "On the Way?",
            confirmButtonText: 'Notify'
          }
          break
        }
        case CustomerCommunications.JOB_STATEMENT.id: {
          this.customerCommunicationsProps = {
            isDialogVisible: true,
            communicationType: communicationType,
            icon: 'mdi-file-document-plus-outline',
            message: 'Send Statement?',
            confirmButtonText: 'Send'
          }
          break
        }
      }
    },
    async handleSendBookingConfirmation(customerCommunicationConfig) {
      this.customerCommunicationsProps.isDialogVisible = false

      if(customerCommunicationConfig.isSendText) {
        let sendBookingConfirmationTextDto = new postCustomerTextBookingConfirmationDto(this.getCurrentJob.id, this.$msal.getCurrentUserId())
        await this.sendCustomerTextBookingConfirmation(sendBookingConfirmationTextDto)
      }

      if(customerCommunicationConfig.isSendEmail) {
        let sendBookingConfirmationDto = resendConfirmationEmailDto(this.$msal.getCurrentUserId())
        await this.resendConfirmationEmail(sendBookingConfirmationDto)
      }

      await this.fetchJunkJobNotes(this.getCurrentJob.id)

      this.resetCustomerCommunicationsProps()
    },
    async handleSendDriverEta(customerCommunicationConfig) {
      // TODO: feature/268 - add hubspot config to enable sending driver eta as email
      this.customerCommunicationsProps.isDialogVisible = false

      const sendCustomerEtaDto = new postCustomerTextEtaDto(this.getCurrentJob.id, customerCommunicationConfig.eta, this.$msal.getCurrentUserId())
      await this.sendCustomerTextEta(sendCustomerEtaDto)

      await this.fetchJunkJobNotes(this.getCurrentJob.id)

      this.resetCustomerCommunicationsProps()
    },
    async handleSendJobStatement(customerCommunicationConfig) {
      this.customerCommunicationsProps.isDialogVisible = false

      if (customerCommunicationConfig.isSendText) {
        const sendJobStatementTextDto = postCustomerTextJobStatementDto(this.getCurrentJob.id, this.getJobDocumentReceiptInfo.blobUrl, this.$msal.getCurrentUserId())
        await this.sendCustomerTextJobStatement(sendJobStatementTextDto)
      }

      if (customerCommunicationConfig.isSendEmail) {
        const recipients = this.getSelectedEmailReceiptRecipients.map(recipient => jobFranchiseDocumentRecipientDto(v4(), recipient.firstName, recipient.lastName, recipient.email, recipient.isPrimary))
        const sendJobStatementDto = generateReceiptDto(this.getCurrentJob.id, this.$msal.getCurrentUserId(), recipients)
        await this.resendJobReceipt(sendJobStatementDto)
      }

      this.setSelectedEmailReceiptRecipients([])
      await this.fetchJunkJobNotes(this.getCurrentJob.id)
      this.resetCustomerCommunicationsProps()
    },
    resetCustomerCommunicationsProps() {
      this.customerCommunicationsProps = {
        isDialogVisible: false,
        communicationType: 0,
        icon: '',
        message: '',
        confirmButtonText: '',
      }
    },
    setSelectedAddress(address) {
      this.selectedAddress = address
    },
    async handleDeleteAddress() {
      await this.deleteCustomerAddress(this.selectedAddress.id).then(() => this.setIsDeleteAddressDialogVisible({}, false))
    },
    async createAddressAndCustomerAddress(createCustomerAddressDto) {
      await this.createAddressRecords({ customerId: this.getCurrentJob.customerId, createCustomerAddressDto: createCustomerAddressDto })
        .then(async customerAddressDto => {
          this.setIsAddressSearchDialogVisible(false)
          await this.addJunkJobAddresses([customerAddressDto.id])
        })
        .then(() => {
          this.setIsAddAddressDialogVisible(false)
        })
    },
    setIsAddAddressDialogVisible(isVisible) {
      this.isAddAddressDialogVisible = isVisible
    },
    setIsAddressSearchDialogVisible(isVisible) {
      this.isAddressSearchDialogVisible = isVisible
    },
    setIsAddTrucksAndEmployeesDialogVisible(isVisible) {
      this.isAddTrucksAndEmployeesDialogVisible = isVisible
    },
    async updateContact(contactDto) {
      await this.editContact(contactDto).then(() => this.setIsEditContactDialogVisible(false, {}))
    },
    setIsEditContactDialogVisible(isVisible, contact) {
      this.isEditContactDialogVisible = isVisible
      this.setSelectedContact(contact)
    },
    setIsJobAbandonmentReasonDialogVisible(isVisible) {
      this.isJobAbandonmentReasonDialogVisible = isVisible
    },
    setIsJobCancellationReasonDialogVisible(isVisible) {
      this.isJobCancellationReasonDialogVisible = isVisible
    },
    setIsJobClaimJobConfirmationDialogVisible(isVisible) {
      this.isJobClaimJobConfirmationDialogVisible = isVisible
    },
    setIsJobIndirectLaborDialogVisible(isVisible) {
      this.isJobIndirectLaborDialogVisible = isVisible
    },
    setOverSlotAllocationWarningDialogOptions(isVisible, scheduledDate, preferredTimeSlotName, confirmationAction, cancellationAction) {
      this.overAllocationConfirmationDialogOptions = {
        isVisible: isVisible,
        scheduledDate: scheduledDate,
        preferredTimeSlotName: preferredTimeSlotName,
        confirmationAction: () => {
          confirmationAction()
          this.resetOverSlotAllocationWarningDialogOptions()
        },
        cancellationAction: () => {
          cancellationAction()
          this.resetOverSlotAllocationWarningDialogOptions()
        }
      }
    },
    resetOverSlotAllocationWarningDialogOptions() {
      this.setOverSlotAllocationWarningDialogOptions(false, "", "", undefined, undefined)
    },
    async dismissIsOverSlotAllocationDialogAndScheduleJob() {
      this.resetOverSlotAllocationWarningDialogOptions()
      await this.scheduleJob()
    },
    setIsScheduledDateInPastDialogVisible(isVisible) {
      this.isPromptScheduledDateInPastDialogVisible = isVisible
    },
    async promptScheduledDateInPastIsOverSlotAllocationDialogOrScheduleJob() {
      if (getIsFirstDateBeforeSecondDate(this.getCurrentJob.scheduledDate, todayAsDate())) {
        this.setIsScheduledDateInPastDialogVisible(true)
      } else {
        await this.promptIsOverSlotAllocationDialogOrScheduleJob()
      }
    },
    scheduledDateInPastDialogConfirmationHandler() {
      this.setIsScheduledDateInPastDialogVisible(false)
      this.promptIsOverSlotAllocationDialogOrScheduleJob()
    },
    async promptIsOverSlotAllocationDialogOrScheduleJob() {
      if (this.getCurrentJob.scheduledDate === undefined || this.getCurrentJob.scheduledDate === null) {
        await this.addSnackbar(errorSnackbar("Unable to schedule job without a service date. Please select a service date."))
        return
      }
      const isOverSlotAllocation = await this.fetchIsOverSlotAllocationOnDateByFranchiseIdDateAndPreferredTimeSlotId({
        franchiseId: this.getCurrentJob.franchiseId,
        date: this.getCurrentJobScheduledDateOrToday,
        preferredTimeSlotId: this.getCurrentJob.preferredTimeSlotId
      })
      isOverSlotAllocation
        ? this.setOverSlotAllocationWarningDialogOptions(
            true,
            this.getFormattedServiceDateAsDayOfWeekMonthDayYear,
            this.getCurrentJob.preferredTimeSlotName,
            () => this.dismissIsOverSlotAllocationDialogAndScheduleJob(),
            () => this.resetOverSlotAllocationWarningDialogOptions()
          )
        : await this.scheduleJob()
    },
    async scheduleJob() {
      const dto = scheduleJobDto(this.$msal.getCurrentUserId())
      await this.scheduleJunkJobByJobId({ jobId: this.getCurrentJob.id, scheduleJobDto: dto }).then(job => {
        this.fetchJobCompletionStatuses(job.id)
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndDateTime(job.franchiseId, job.scheduledDate)
        this.fetchJunkJobNotes(job.id)
        this.fetchCustomerContactsAndAddresses(job.customerId)
      })
    },
    async abandonJob(selectedAbandonmentReasonId) {
      const dto = abandonJobDto(this.$msal.getCurrentUserId(), selectedAbandonmentReasonId)
      await this.abandonJunkJobByJobId({ jobId: this.getCurrentJob.id, abandonJobDto: dto }).then(job => {
        this.setIsJobAbandonmentReasonDialogVisible(false)
        this.fetchJobCompletionStatuses(job.id)
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndDateTime(job.franchiseId, job.scheduledDate)
        this.fetchJunkJobNotes(job.id)
      })
    },
    async cancelJob(selectedCancellationReasonId) {
      const dto = cancelJobDto(this.$msal.getCurrentUserId(), selectedCancellationReasonId)
      await this.cancelJunkJobByJobId({ jobId: this.getCurrentJob.id, cancelJobDto: dto }).then(job => {
        this.setIsJobCancellationReasonDialogVisible(false)
        this.fetchJobCompletionStatuses(job.id)
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndDateTime(job.franchiseId, job.scheduledDate)
        this.fetchJunkJobNotes(job.id)
      })
    },
    async closeJob() {
      const dto = closeJobDto(this.$msal.getCurrentUserId())
      await this.closeJunkJobByJobId({ jobId: this.getCurrentJob.id, closeJobDto: dto }).then(job => {
        this.fetchJobCompletionStatuses(job.id)
        this.fetchJunkJobNotes(job.id)
      })
    },
    async claimJob(employeeIds, truckIds, drivers) {
      const defaultStartAndEndDateTime = formatTimeAsDateUtc(this.getCurrentJob?.scheduledDate, "08:00")
      const dto = claimJobDto(this.$msal.getCurrentUserId(), this.getCurrentJob.id, employeeIds, truckIds, drivers, defaultStartAndEndDateTime)
      await this.claimJunkJobByJobId({ jobId: this.getCurrentJob.id, claimJobDto: dto }).then(job => {
        this.setIsJobClaimJobConfirmationDialogVisible(false)
        this.fetchJobCompletionStatuses(job.id)
        this.fetchJunkJobTrucks(job.id)
        this.fetchJunkJobEmployees(job.id)
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndDateTime(job.franchiseId, job.scheduledDate)
        this.fetchJunkJobNotes(job.id)
      })
    },
    async disclaimJob() {
      const dto = disclaimJobDto(this.$msal.getCurrentUserId())
      await this.disclaimJunkJobByJobId({ jobId: this.getCurrentJob.id, disclaimJobDto: dto }).then(job => {
        this.setIsJobClaimJobConfirmationDialogVisible(false)
        this.fetchJobCompletionStatuses(job.id)
        this.fetchScheduleSlotAllocationsByFranchiseIdYearAndDateTime(job.franchiseId, job.scheduledDate)
        this.fetchJunkJobTrucks(job.id)
        this.fetchJunkJobNotes(job.id)
      })
    },
    async uncompleteJob() {
      const dto = uncompleteJobDto(this.getCurrentJob.id, this.$msal.getCurrentUserId())
      await this.uncompleteJunkJobByJobId(dto).then(job => {
        this.fetchJobCompletionStatuses(job.id)
        this.fetchJunkJobNotes(job.id)
      })
    },
    async completeJobHandler() {
      if (this.getJobDocumentReceiptInfo.isGenerated) {
        await this.completeJob()
      } else {
        this.setIsReceiptNotCompletedWarningDialogVisible(true)
      }
    },
    setIsReceiptNotCompletedWarningDialogVisible(isVisible) {
      this.isReceiptNotCompletedWarningDialogVisible = isVisible
    },
    createGenerateReceiptDto() {
      let generateReceiptDto = {
        jobId: this.getCurrentJob.id,
        employeeId: this.$msal.getCurrentUserId(),
        jobFranchiseDocumentRecipientDtos: []
      }

      if (this.getPrimaryContactDetails.refusedToProvideEmail === false && this.getPrimaryContactDetails.emailPrimary?.length > 0) {
        let dto = jobFranchiseDocumentRecipientDto(v4(), this.getPrimaryContactDetails.firstName, this.getPrimaryContactDetails.lastName, this.getPrimaryContactDetails.emailPrimary, true)
        generateReceiptDto.jobFranchiseDocumentRecipientDtos.push(dto)
      }

      return generateReceiptDto
    },
    async completeJobAndGenerateReceipt() {
      if (!this.hasReceipt) {
        const jobReceiptDto = createReceiptDto(this.getCurrentJob.id, this.getCurrentJob.franchiseId, this.$msal.getCurrentUserId())

        try {
          await this.createJobReceipt(jobReceiptDto)
        } catch (error) {
          console.error("Error creating Job Statement")
          return
        }
      }

      const dto = this.createGenerateReceiptDto()
      await this.generateJobReceipt(dto).then(async () => {
        await this.completeJob().then(() => {
          this.setIsReceiptNotCompletedWarningDialogVisible(false)
        })
      })
    },
    async completeJob() {
      const dto = completeJobDto(this.$msal.getCurrentUserId())
      await this.completeJunkJobByJobId({ jobId: this.getCurrentJob.id, completeJobDto: dto }).then(job => {
        this.fetchJobCompletionStatuses(job.id)
        this.fetchJunkJobNotes(job.id)
      })
    },
    async putJobLeadSource(event) {
      const dto = putLeadSourceDto(this.getCurrentJob.id, event, this.$msal.getCurrentUserId())
      await this.putLeadSource(dto).then(() => {
        this.$refs["job-details-card"]?.$refs["job-details-card-lead-source-selector"]?.blur()
      })
    },
    async fetchScheduleSlotAllocationsByFranchiseIdYearAndDateTime(franchiseId, dateTime) {
      const yearAndMonth = getYearAndMonthFromDateTime(dateTime)
      const fromDate = getMinimumDateOrFirstOfMonth(yearAndMonth)
      const toDate = getLastDayOfMonthByYearAndMonth(yearAndMonth)
      const dto = fetchScheduleSlotDto(franchiseId, fromDate, toDate)
      await this.fetchScheduleSlotAllocations(dto)
    },
    async resetLoadingStateAndNavToDashboard() {
      await this.setAllJobComponentsLoadingState(false)
      await router.replace({ name: "Dashboard" })
    },
    async callInitialLoad() {
      await this.fetchJunkJobByJobIdInitial(this.jobIdFromUrl)
        .then(async job => {
          if (this.getUserOperatingUnitIds.find(ouid => ouid === job.operatingUnitId) !== undefined) {
            let date = formatAsDateOnly(job.scheduledDate)
            this.fetchActiveFranchiseCapacitiesByFranchiseIdAndDate({ franchiseId: job.franchiseId, date: date })
            this.fetchActiveFranchisesAndSatelliteOfficesInOperatingUnits(this.getUserOperatingUnitIds) // not dependent on job response
            this.fetchCustomerContactsAndAddresses(job.customerId)
            this.fetchJunkJobAddresses(job.id)
            this.fetchActiveFranchiseTaxes({ franchiseId: job.franchiseId, date: date })
            this.fetchJobCompletionStatuses(job.id)
            this.fetchJunkJobNotes(job.id)
            this.fetchJunkJobTrucks(job.id)
            this.fetchTimeSlots()
            this.fetchJunkJobEmployees(job.id)
            this.fetchActiveJunkTrucksInOperatingUnitByDate({ date: date, operatingUnitId: job.operatingUnitId })
            this.fetchLeadSources()
            this.fetchJobReceipt()
            this.fetchApplicableDiscounts(job.id)
          } else {
            await this.resetLoadingStateAndNavToDashboard()
          }
        })
        .catch(() => {
          if (Object.keys(this.getCurrentJob).length !== 0 && this.getUserOperatingUnitIds.find(ouid => ouid === this.getCurrentJob.operatingUnitId) === undefined) {
            this.resetLoadingStateAndNavToDashboard()
          }
        })
    },
    async fetchJobData() {
      await this.callInitialLoad()
    }
  },
  async created() {
    this.jobIdFromUrl = this.$route.params.jobId
    await this.fetchJobData()
  },
  async beforeDestroy() {
    await this.clearJobState()
  }
}
</script>
